/* eslint-disable test-selectors/button */
import React, { type DetailedHTMLProps, type FC } from 'react';
import styled from '@emotion/styled';

const Button = styled.button(
  {
    background: 'none',
    border: 'none',
    padding: 0,
    margin: '0'
  },
  (props) => ({
    cursor: props.disabled ? 'not-allowed' : 'pointer',
    'data-testid': props['data-testid'] || 'unstyled-button'
  })
);

const UnstyledButton: FC<DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>> = ({
  type = 'button',
  ...rest
}) => <Button type={type} {...rest} />;

export default UnstyledButton;
